@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  height: 100%;
  padding: 0 24px;

  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
}

.searchBox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  row-gap: 20px;
  background-color: #fff;
  height: 100%;

  @media (--viewportLarge) {
    border: 1px solid #e5e5e6;
    box-shadow: 0px 2px 5px 0px #0000001a;
    flex-direction: row;
    padding: 7px 8px;
    border-radius: 604px;
    height: auto;
  }
}

.searchBoxItem {
  /* position: relative; */
  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--colorGrey100);
  border-radius: 25px;

  @media (--viewportLarge) {
    height: 35px;
    border: none;
    border-bottom: 0px solid transparent;
    border-radius: 0px;

    &:nth-child(2) {
      border-left: 1px solid var(--colorGrey100);
      border-right: 1px solid var(--colorGrey100);
    }

    &:nth-child(1) {
      width: 135px;
    }

    &:nth-child(2) {
      width: 120px;
    }

    &:nth-child(3) {
      width: 140px;
    }
  }

  @media (--viewportMLarge) {
    &:nth-child(1) {
      width: 177px;
    }

    &:nth-child(2) {
      width: 133px;
    }

    &:nth-child(3) {
      width: 247px;
    }
  }
}

.searchBoxItemOutsideClick {
  width: 100%;
}

.searchBoxItemContent {
  display: flex;
  position: relative;
  align-items: center;
  padding: 5px 28px;
  cursor: pointer;
  transition: background-color 0.2s linear;
  display: flex;
  height: 100%;
  width: 100%;

  & svg {
    margin-right: 10px;
    width: 20px;
  }
}

.datesRangeItem {
  padding: 0px 0px 0px 28px;
  @media (--viewportLarge) {
    padding: 0px 12px 0px 24px;
  }
}

.searchBoxItemInput {
  font-size: 14px;
  font-weight: 600;
  color: var(--colorBlack);
  height: 25px;
  overflow: hidden;
  white-space: nowrap;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.datesRangeLabel {
  font-size: 14px;
  line-height: 16px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  width: 100%;

  @media (--viewportLarge) {
    flex-direction: column;
    justify-content: center;
  }
}

.datesRangePlaceholder {
  margin: unset;
  font-size: 14px;
  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.keywordInput {
  padding: 0;
  line-height: 25px;
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: var(--colorBlack);

  &:focus,
  &:hover {
    outline: none;
    border: 0px solid transparent;
    box-shadow: none;
  }

  @media (--viewportMedium) {
    font-size: 16px;
  }

  &::placeholder {
    font-size: 14px;
    font-weight: 600;
    color: var(--colorGrey300);

    @media (--viewportMedium) {
      font-size: 16px;
    }
  }
}

.placeholder {
  color: var(--colorGrey300);
}

.suggestionPanel {
  position: absolute;
  top: 55px;
  right: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  overflow: hidden;
  z-index: 100;

  & ul {
    margin: 0;

    & li {
      padding: 10px 20px;
      cursor: pointer;
      transition: all 0.2 linear;

      &:hover {
        background-color: var(--colorGrey800);
      }
    }
  }
}

.locationAutocompletePanel {
  height: 24px;
  flex-grow: 1;
}

.locationAutocomplete {
  height: 24px;
}

.locationAutocompleteIcon {
  display: none;
}

.locationAutocompleteInput {
  background-color: transparent;
  border-radius: 0;
  border: 0px solid transparent;
  border-bottom: 0px solid transparent;
  outline: none;
  padding: 0;
  width: 100%;
  font-size: 14px;
  font-weight: 600;

  @media (--viewportMedium) {
    font-size: 16px;
  }

  &:hover {
    border: 0px solid transparent;
    border-bottom: 0px solid transparent;
  }

  &:focus {
    background-color: transparent;
    border-bottom: 0px solid transparent;
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &::placeholder {
    font-size: 14px;
    font-weight: 600;
    color: var(--colorGrey300);

    @media (--viewportMedium) {
      font-size: 16px;
    }
  }
}

.locationAutocompletePredict {
  position: absolute;
  top: 34px;
  left: -29px;
  right: -29px;
  background-color: var(--colorWhite);
  border-radius: 25px;
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
  overflow: hidden;

  @media (--viewportLarge) {
    top: 41px;
    left: -30px;
    width: 295px;
  }
}

.searchSubmit {
  margin-top: auto;
  margin-bottom: 20px;
  width: auto;
  padding: 0px 30px;
  column-gap: 15px;
  display: flex;
  align-items: center;
  margin-left: auto;
  position: fixed;
  bottom: -2px;
  right: 24px;
  height: 48px;
  min-height: 48px;
  @media (--viewportMedium) {
    position: static;
  }
  @media (--viewportLarge) {
    justify-content: center;
    min-height: unset;
    height: 38px;
    width: 100%;
    border-radius: 0;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    width: 38px;
    border-radius: 50%;
    padding: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
}

.searchText {
  display: block;
  @media (--viewportLarge) {
    display: none;
  }
}

.selectDatePanel {
  position: absolute;
  min-width: 604px;
  left: 50% !important;
  transform: translateX(-50%);
  right: 0;
  border-radius: 25px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowPopup);
  overflow: hidden;
  z-index: 100;
  width: calc(100% + 4px);

  & :global(.CalendarDay:hover .renderedDay) {
    border-radius: 50% !important;
  }
  top: 128px;

  @media (--viewportLarge) {
    top: 90px;
  }

  @media (max-width: 630px) {
    height: 400px;
    max-height: 400px;
    min-width: auto;

    & :global(.DayPicker_weekHeader) {
      &:last-of-type {
        top: -1px;
        & :global(.DayPicker_weekHeader_ul) {
          background-color: white;
        }
      }
    }

    & :global(div) {
      &:last-of-type {
        height: 100%;
      }
    }

    /* & :global(.DateRangeController_inputRoot__L-LGZ) {
      height: 100%;
    } */

    & :global(.CalendarMonthGrid) {
      overflow-y: hidden;
    }

    & :global(.CalendarMonth_caption) {
      padding-top: 14px;
      padding-bottom: 20px;
    }

    & :global(.DayPicker_weekHeader) {
      padding: 0px 25px 0px 0px;
    }

    & :global(.DayPickerNavigation_button) {
      display: none;
    }
  }

  @media (--viewportLarge) {
    width: 100%;
    min-width: none;
    left: 0;
    top: 55px;
    right: unset;
  }
}

.hideOnDesktop {
  @media (--viewportLarge) {
    display: none;
  }
}

.dateRangeLabelWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dateRangeLabelWrapperInner {
  flex: 1;
  display: flex;
  flex-direction: row;
  @media (--viewportLarge) {
    flex-direction: column;
  }
}

.datesRangeCancelIcon {
  margin-right: 10px !important;
  @media (--viewportLarge) {
    margin-right: 0px !important;
  }
}

.bottomActions {
  display: flex;
  align-items: center;
  margin: 0 20px 20px 30px;
}

.actionsLeft {
  display: flex;
  margin: 0 0 0 auto;
  gap: 20px;
}

.closeDate {
  min-height: 38px;
  height: 38px;
  width: 100px;
}

.areaSearchDropdown {
  position: absolute;
  min-width: 300px;
  /* transform: translateX(-50%); */
  border-radius: 25px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowPopup);
  overflow: hidden;
  z-index: 100;
  width: calc(100% + 4px);
  padding: 24px;
  top: 59px;
  left: -2px !important;
  @media (--viewportLarge) {
    top: 46px;
    left: -8px !important;
  }
}

.areaSearchHeading {
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
  line-height: normal;
  padding: 0;
  margin: 0;
  margin-left: 8px;
}

.areaSearchSegment {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  width: 100%;
  margin-top: 20px;
}

.areaSearchCard {
  padding: 8px;
  border-radius: 16px;
  width: 135px;
  max-width: 50%;
  &:hover {
    background-color: #ebebeb;
  }
  @media (--viewportMedium) {
    width: 125px;
  }
}

.areaSearchImgWrapper {
  border-radius: 16px;
  border: 1px solid #dddddd;
  width: 100%;
  height: 80px;
  overflow: hidden;
}

.areaSearchImg {
  height: 100%;
  width: 100%;
  object-fit: fill;
  background-color: #fbfcfc;
}

.areaSearchText {
  font-size: 14px;
  margin-top: 10px;
  font-weight: normal;
}

.keywordCancelIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 13px;
  right: 0px;
  cursor: pointer;
}

.countryImgWrapper {
  width: 40px;
}

.countryImg {
  height: 20px;
  object-fit: cover;
  background-color: transparent;
}

.countryItem {
  padding: 8px;
  display: flex;
  flex-direction: row;
  border-radius: 14px;
  cursor: pointer;
  &:hover {
    background-color: #ebebeb;
  }
}
