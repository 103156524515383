@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  min-width: 100%;
  justify-content: flex-start;

  @media (--viewportMedium) {
      min-width: 400px;
      /* justify-content: space-between; */
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 0px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
  margin-top: 0px;
  text-align: start;
  margin-bottom: 40px;
  padding-top: 0px;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
  color: var(--marketplaceColor);
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
  color: var(--colorBlack);
}

.loginError{
  text-align: center;
  color: var(--marketplaceColor);
  margin-bottom: 10px;
}

.align {
  text-align: center;
  justify-self: start;

  @media (--viewportMedium) {
    text-align: center;
    justify-self: center;
  }
}

.title {
  composes: align;
  &h2,
  &h1 {
    color: var(--marketplaceColor);
  }
}

.description{
  composes: align;
}

.sectionDetails .description {
  font-size: 16px;
  line-height: 22.4px;
  color: var(--colorGrey500);
  padding-bottom: 15px;
}
